<template>
  <div class="app-container">
    <el-form ref="addOutboundForm" :model="outboundForm" :rules="rules" label-width="100px">
      <div class="el-lt" style="width:100%;">
        <el-row :span="24">
          <el-col :span="6">
            <el-form-item :class="$i18n.locale" label="物理仓库" prop="outboundCode">
              <el-select v-model="value" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :class="$i18n.locale" label="逻辑仓库" prop="outboundCode">
              <el-select v-model="value" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
    </el-form>
    <el-row class="grid-content bg-purple-dark">
      <el-col :span="20" class="bg-purple-title">概览数据</el-col>
    </el-row>
    <div style="width: 100;display: flex;justify-content: space-around;">
      <div v-for=" (val,index) in datas" :key="index" style="width:20%;text-align: center;">
        <p>{{ val.name }}</p>
        <p>{{ val.valu }}</p>
      </div>
    </div>
    <el-row class="grid-content bg-purple-dark" type="flex">
      <span class="bg-purple-title">明细数据</span>
      <!-- <span>
        <el-button type="primary" :disabled="show" :loading="releaseLoading">
          {{ $t('title.Shortagecompletion') }}</el-button>
        <el-button>{{ $t('page.export') }}</el-button>
      </span> -->
    </el-row>
    <el-table
      ref="tableData"
      v-loading="tableLoading"
      class="mb-3"
      :data="tableData"
      :header-cell-style="{background:'#fafafa'}"
      max-height="550px"
    >
      <el-table-column :label="$t('page.No')" type="index" width="80" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column prop="physicalware" label="物理仓库" width="180" align="center" />
      <el-table-column prop="Logicalware" label="逻辑仓库" width="180" align="center" />
      <el-table-column prop="lock" label="总库存" width="120" align="center">
        <template slot-scope="scope">{{ scope.row.lock }}</template>
      </el-table-column>
      <el-table-column prop="outgoing" label="在途库存" width="120" align="center">
        <template slot-scope="scope"><router-link style="color:#1890ff;" :to="{ path: '/inventory-inquiry/sku-dimension/sku-inventory-transit'}">{{ scope.row.outgoing }}</router-link></template>
      </el-table-column>
      <el-table-column prop="available" label="在库库存" width="120" align="center">
        <template slot-scope="scope">{{ scope.row.available }}</template>
      </el-table-column>
      <el-table-column prop="available" label="可用库存" width="120" align="center">
        <template slot-scope="scope"><router-link style="color:#1890ff;" :to="{ path: '/inventory-inquiry/sku-dimension/sku-available-inventory'}">{{ scope.row.available }}</router-link></template>
      </el-table-column>
      <el-table-column prop="commitment" label="承诺库存" width="120" align="center">
        <template slot-scope="scope"><router-link style="color:#1890ff;" :to="{ path: '/inventory-inquiry/sku-dimension/sku-commitment-inventory'}">{{ scope.row.commitment }}</router-link></template>
      </el-table-column>
      <el-table-column prop="lock" label="锁定库存" width="120" align="center">
        <template slot-scope="scope"><router-link style="color:#1890ff;" :to="{ path: '/inventory-inquiry/sku-dimension/sku-lock-inventory'}">{{ scope.row.lock }}</router-link></template>
      </el-table-column>
      <el-table-column prop="outgoing" label="待出库库存" width="120" align="center">
        <template slot-scope="scope"><router-link style="color:#1890ff;" :to="{ path: '/inventory-inquiry/sku-dimension/sku-outgoing-inventory'}">{{ scope.row.outgoing }}</router-link></template>
      </el-table-column>
      <el-table-column prop="outgoing" label="不良品库存" width="120" align="center">
        <template slot-scope="scope">{{ scope.row.outgoing }}</template>
      </el-table-column>
    </el-table>
    <el-row class="grid-content bg-blue" type="flex" justify="center">
      <el-button @click="cancle()">{{ $t('title.back') }}</el-button>
    </el-row>
  </div>
</template>
<script>
// import PlatSelect from '@/components/ListSelection/PlatList'
// import PlatformSelect from '@/components/ListSelection/platform'

export default {
  data() {
    return {
      headlineProductsList: [],
      outboundForm: {
        outboundCode: '543656345',
        productType: '',
        productCode: '',
        inspectionWarehouse: '',
        targetWarehouseName: '',
        sourceCode: '',
        outboundStatus: '0', // 出库状态
        outboundType: '',
        outboundGoodsPlatformId: '',
        sendSellPlatformId: '',
        sendSiteCode: '',
        outPairs: 0, // 应出库数 新增不需要
        sendBoxNumber: 0, // 应出库箱数 新增不需要
        deliveryTime: '', // 预计发货时间
        remark: '',
        outboundWarehouseId: '' // 当前仓库id`
      },
      datas: [
        { name: '总库存', valu: '5000' },
        { name: '在途库存', valu: '3200' },
        { name: '在库库存', valu: '2889' },
        { name: '可用库存', valu: '1528' },
        { name: '承诺库存', valu: '200' },
        { name: '锁定库存', valu: '50' }

      ],
      tableLoading: false,
      tableData: [
        { Logicalware: 'NJ', BU: 'Man', physicalware: 'AT', sku: 'B225-2A1X305-H60M1-RLBJ122', style: 'Nina_Style1', color: 'red', size: '5', commitment: '43287', lock: '5700', outgoing: '785', available: '45865' },
        { Logicalware: 'MMT', BU: 'Women 1', physicalware: 'JPX', sku: 'D222-1A8AA11-340WD-281283U179', style: 'SDHS2222W', color: 'black', size: '5.5', commitment: '28925', lock: '5930', outgoing: '67876', available: '128' },
        { Logicalware: 'Chicago', BU: 'Women 2', physicalware: 'MMT', sku: 'D222-1A8AA11-340WD-281283U179', style: 'DHS215-1', color: 'blue', size: '6', commitment: '58746', lock: '10', outgoing: '154', available: '48551' },
        { Logicalware: 'CS-JPX', BU: 'Kids', physicalware: 'WG', sku: '815-BLACK-GREY-SZ-1-1', style: 'SDSA224W', color: 'gray', size: '6.6', commitment: '89356', lock: '296', outgoing: '457', available: '1185420' },
        { Logicalware: 'Ebay', BU: 'Bruno Marc', physicalware: 'Top', sku: '815-BLACK-GREY-SZ-1-2', style: 'SDFL223K', color: 'red', size: '7', commitment: '1500', lock: '3280', outgoing: '898741', available: '58' },
        { Logicalware: 'Amazon', BU: 'NORTIV 8', physicalware: 'Max', sku: '815-BLACK-GREY-SZ-1-0', style: 'LANGDO-2', color: 'white', size: '9', commitment: '500', lock: '5000', outgoing: '435135', available: '600' }

      ]

      // rules: {
      //   depositType: [{ required: true, message: this.$t('title.selectdeliverytype'), trigger: 'change' }],
      //   receiveType: [{ required: true, message: this.$t('title.selectdeliverymethod'), trigger: 'change' }]
      // }
    }
  },
  computed: {
    // isWalmart() {
    //   const sendSiteCode = this.outboundForm.sendSiteCode
    //   return sendSiteCode === 'US-Walmart/109' || sendSiteCode === 'US-Walmart'
    // },
    // getLogisticsPlanParams() {
    //   const { inspectionWarehouse, productCode, targetWarehouseName, sendSiteCode } = this.outboundForm
    //   return Object.assign({}, { inspectionWarehouseCode: inspectionWarehouse, winitProductCode: productCode, destinationWarehouseCode: targetWarehouseName, siteCode: sendSiteCode.split('/')[0] })
    // }

  },
  mounted() {

  },
  methods: {

  }
}
</script>
<style lang="scss" scope>
.bg-purple-dark {
  border: 1px solid #bae7ff;
  background: #e6f7ff;
  color: #1890ff;
  size: 14px;
  padding: 6px;
  margin-bottom: 10px;
  .bg-purple-title {
    line-height: 36px;
    text-indent: 20px;
  }
}
.float-left {
  float: left;
}
</style>
